import { Icon, Toast, Image, } from 'vant'

export default({
  components:{
    'van-icon': Icon,
    'van-image': Image,
  },
  data() {
    return {
      apis:{//接口列表
        info:'/content/findContent',//特色活动详情
      },
      contentId: null,//活动ID
      bizType: 11,//活动的业务类型代码
      active: {},//活动详情
    }
  },
  mounted() {
    const me = this;
    me.contentId = me.$route.params.id;
    me.loadActive();
  },
  created(){

    this.getShareInfo(
      this.$global.shareTitle,
      this.$global.visitUrl,
      this.$global.shareImage,
      this.$global.shareIntro,
    );
  },

  methods: {
    loadActive(){//加载活动列表
      const me = this;
      me.$get({
        url: me.apis.info,
        params: {
          contentId:  me.contentId,
          token: me.$global.token
        }
      }).then(rsp => {
        me.active = rsp;
      });
    },
    praiseOrCollect(){//点赞或收藏后触发
      this.loadActive();
    }
  }
})
